import React from 'react'
import { Link, navigate } from 'gatsby'
import '../styles/note-list.css'
import kebabCase from 'lodash/kebabCase'

export default function NoteList({ notes }) {

  const handleCardClick = (event, slug) => {
    // Prevents the navigation if the click was on a link or any element inside a link
    if (event.target.closest('a')) {
      return
    }
    navigate(slug)
  }

  return (
    <div className="note-list-block note-list-cards note-list-main">
      {notes.map((data, index) => {
        const imgSrc = data.node.frontmatter?.imagePath?.publicURL
        const slug = data.node.fields.slug
        const title = data.node.fields.title

        return (
          <article
            className="note-list-area note-list-box-feed"
            key={index}
            role="article"
            tabIndex={0}
            aria-label={`Read more about ${title}`}
            onClick={event => handleCardClick(event, slug)}
            onKeyDown={event => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault()
                navigate(slug)
              }
            }}
          >
            <h4 className="note-list-title">
              <Link to={slug}>{title}</Link>
            </h4>
            <img src={imgSrc} alt={title} />
            <p className="note-list-excerpt">
              {data.node.fields.excerpt
                ? data.node.fields.excerpt
                : data.node.excerpt}
            </p>

            {data.node.frontmatter.tags?.length ? (
              <p
                className="note-list-tag-list"
                onClick={e => e.stopPropagation()}
              >
                Tagged with:{' '}
                {data.node.frontmatter.tags.map((tag, index) => (
                  <span key={index}>
                    <Link to={`/tags/${kebabCase(tag)}`}>#{tag}</Link>
                    {index < data.node.frontmatter.tags.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </p>
            ) : null}
          </article>
        )
      })}
    </div>
  )
}
