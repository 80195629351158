import React from 'react'
import { Link } from 'gatsby'
import '../styles/pager.css'

export default function Pager({ context }) {
  return (
    <div className="pager-container">
      <div className="pager-links">
        {context.previousPagePath && (
          <Link
            to={context.previousPagePath}
            className="pager-link"
            aria-label="Go to previous page"
          >
            &larr; Previous
          </Link>
        )}
        {context.nextPagePath && (
          <Link
            to={context.nextPagePath}
            className="pager-link"
            aria-label="Go to next page"
          >
            Next &rarr;
          </Link>
        )}
      </div>
      <div className="all-tags-link">
        <Link to="/tags">See All Tags</Link>
      </div>
    </div>
  )
}
